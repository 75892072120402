import isNil from 'lodash/isNil';
import { LOG_TYPE, logger } from '@emobg/web-utils';
import { useTranslations } from '@/composable/App/Translations';

const { $t } = useTranslations();

export const composeAlertEmailToLinkData = ({ serviceEmail }) => {
  if (isNil(serviceEmail)) {
    logger.message('"serviceEmail" argument has not been provided.', LOG_TYPE.warning);
  }
  const link = `<a href="mailto:${serviceEmail}?subject=${$t('PersonalInformationForm.alerts.b2c_email_help.subject')}">${serviceEmail}</a>`;
  return $t('PersonalInformationForm.alerts.b2c_inputs_disabled', { email: link });
};
